import * as CmpBackendService from "./services/CmpBackendService";
import * as PublicApiService from "./services/PublicApiService";

var countries = require("i18n-iso-countries");
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

var languages = require("@cospired/i18n-iso-languages");
languages.registerLocale(require("@cospired/i18n-iso-languages/langs/en.json"));

const registrationModule = {
  namespaced: true,
  state: () => ({
    locale: "en",
    cannotLoad: false,
    registrationRandom:
      typeof window !== "undefined" ? window.crypto.getRandomValues(new Uint32Array(2)).join("") : "123456789",
    tenant: process.env.VUE_APP_TENANT,
    uiColor: "#625F4E",
    requiredResources: null,
    documents: null,
    uploadResult: null,
    registrationCompleted: false,
    documentUploadSuccess: false,
    documentUploadError: false,
    id: null,
    personalInfo: {
      gender: null,
      firstname: null,
      lastname: null,
      email: null,
      phone: null,
      address: {
        countryChooser: "US",
        line1: null,
        line2: null,
        city: null,
        zip: null,
        state: null,
        country: null,
      },
    },
    professionalInfo: {
      employer: null,
      workEmail: null,
      workType: null,
      jobDuties: null,
      linkedIn: null,
      jobTitle: null,
    },
    professionalReferences: {
      reference1: {
        name: null,
        email: null,
        relationship: null,
      },
      reference2: {
        name: null,
        email: null,
        relationship: null,
      },
    },
    eligibility: {
      highestLevel: null,
      yearsLeaderCustomerContact: null,
      yearsLeaderManagement: null,
      yearsAgent: null,
    },
    keyExperience: {
      competency1: {
        competency: null,
        text: null,
      },
      competency2: {
        competency: null,
        text: null,
      },
    },
    demographics: {
      businessModel: null,
      annualReviews: null,
      stayAtCurrentEmployer: null,
      customerSatisfaction: null,
      channelsAgent: [],
      channelsLeader: [],
    },
    certificationType: {
      certId: null,
      certificationPath: null,
    },
    consentAccepted: {},
    noId: false,
    bookingInfo: null,
  }),
  getters: {
    noId: (state) => {
      return state.noId;
    },
    availableCountriesForBilling: (state) => {
      var allCountries = countries.getNames(state.locale, { select: "official" });
      var countriesList = Object.keys(allCountries)
        .map((code) => {
          var elem = {};
          elem.name = allCountries[code];
          elem.code = code.toLowerCase();
          return elem;
        })
        .sort(compareCountriesAndLanguages);
      return countriesList;
    },
    competencies: (state) => {
      if (state.certificationType.certId === "CMPCA") {
        return [
          "Autonomy",
          "Emotional Intelligence",
          "Adaptability",
          "Time Management",
          "Customer Service",
          "Operational Excellence",
          "Communication",
        ];
      } else if (state.certificationType.certId === "CMPCL") {
        return [
          "Stability",
          "Autonomy",
          "Emotional Intelligence",
          "Mindfulness",
          "Leadership",
          "Functional Expertise",
          "360 Coaching",
        ];
      } else {
        return [];
      }
    },
    cannotLoad: (state) => {
      return state.cannotLoad;
    },
    registrationRandom: (state) => {
      return state.registrationRandom;
    },
    locale: (state) => {
      return state.locale;
    },
    tenant: (state) => {
      return state.tenant;
    },
    documents: (state) => {
      return state.documents;
    },
    uiColor: (state) => {
      return state.uiColor;
    },
    documentsEmpty: (state) => {
      var empty = true;
      if (state.documents != null) {
        for (var id in state.documents) {
          if (state.documents[id] != null && state.documents[id].length > 0) {
            if (state.documents[id][0].file != null) {
              empty = false;
            }
          }
        }
      }
      return empty;
    },
    uploadResult: (state) => {
      return state.uploadResult;
    },
    documentUploadSuccess: (state) => {
      return state.documentUploadSuccess;
    },
    documentUploadError: (state) => {
      return state.documentUploadError;
    },
    requiredResources: (state) => {
      return state.requiredResources;
    },
    consentDeclarations: () => {
      if (typeof window !== "undefined") {
        return window.i18n.t("registration.consentDeclarations");
      } else {
        return null;
      }
    },
    consentAccepted: (state) => {
      return state.consentAccepted;
    },
    registrationCompleted: (state) => {
      return state.registrationCompleted;
    },
    personalInfo: (state) => {
      return state.personalInfo;
    },
    certificationType: (state) => {
      return state.certificationType;
    },
    eligibility: (state) => {
      return state.eligibility;
    },
    keyExperience: (state) => {
      return state.keyExperience;
    },
    demographics: (state) => {
      return state.demographics;
    },
    professionalInfo: (state) => {
      return state.professionalInfo;
    },
    professionalReferences: (state) => {
      return state.professionalReferences;
    },
    paid: () => {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.has("b27b87a54452eb58");
    },
    bookingInfo: (state) => {
      return state.bookingInfo;
    },
    registrationData: (state, getters) => {
      let registrationData = {
        id: state.id,
        examInfo: {
          tenant: state.tenant,
          certId: "CMP",
          paid: getters.paid,
        },
        metaInfo: {
          userInterfaceLanguage: state.locale,
          submitTimestamp: new Date().toISOString(),
          userAgent: navigator.userAgent,
          screenSize: typeof window !== "undefined" ? window.innerWidth + "x" + window.innerHeight : "",
        },
        certificationType: state.certificationType,
        personalInfo: state.personalInfo,
        keyExperience: state.keyExperience,
        demographics: state.demographics,
        professionalInfo: state.professionalInfo,
        //professionalReferences: state.professionalReferences,
        eligibility: state.eligibility,
        consentDeclarations: getters.consentDeclarations,
        consentAccepted: state.consentAccepted,
        registrationRandom: state.registrationRandom,
        action: "create",
        bookingAgent: state.bookingInfo.bookingAgent ?? null,
        transactionId: state.bookingInfo.transactionId ?? null,
      };

      if (state.uploadResult && state.uploadResult.uploaded) {
        registrationData.documents = state.uploadResult.uploaded;
      }

      return registrationData;
    },
    id: (state) => {
      return state.id;
    },
  },
  mutations: {
    setLocale(state, locale) {
      state.locale = locale;
      if (locale === "en" && state.tenant !== "certible") {
        document.title = process.env.VUE_APP_TITLE_EN;
      }
      if (locale === "de" && state.tenant !== "certible") {
        document.title = process.env.VUE_APP_TITLE_DE;
      }
    },
    setDate(state, date) {
      state.date = date;
    },
    setTime(state, time) {
      state.time = time;
    },
    setRequiredResources(state, requiredResources) {
      state.requiredResources = requiredResources;
    },
    setDocuments(state, documents) {
      state.documents = documents;
    },
    setDocumentUploadSuccess(state, documentUploadSuccess) {
      state.documentUploadSuccess = documentUploadSuccess;
    },
    setDocumentUploadError(state, documentUploadError) {
      state.documentUploadError = documentUploadError;
    },
    setConsentAccepted(state, consentAccepted) {
      state.consentAccepted = consentAccepted;
    },
    setLanguage(state, language) {
      state.language = language;
    },
    setPersonalInfo(state, personalInfo) {
      state.personalInfo = personalInfo;
    },
    setKeyExperience(state, keyExperience) {
      state.keyExperience = keyExperience;
    },
    setDemographics(state, demographics) {
      state.demographics = demographics;
    },
    setCertificationType(state, certificationType) {
      state.certificationType = certificationType;
    },
    setProfessionalInfo(state, professionalInfo) {
      state.professionalInfo = professionalInfo;
    },
    setProfessionalReferences(state, professionalReferences) {
      state.professionalReferences = professionalReferences;
    },
    setRegistrationCompleted(state, registrationCompleted) {
      state.registrationCompleted = registrationCompleted;
    },
    setUploadResult(state, uploadResult) {
      state.uploadResult = uploadResult;
    },
    setSelectedCertId(state, certId) {
      state.certId = certId;
    },
    setUploadStatus(state, info) {
      state.documents[info.id][info.index].uploadStatus = info.status;
    },
    setEligibility(state, eligibility) {
      state.eligibility = eligibility;
    },
    setNoId(state, noId) {
      state.noId = noId;
    },
    setId(state, id) {
      state.id = id;
    },
    setBookingInfo(state, bookingInfo) {
      state.bookingInfo = bookingInfo;
    },
    setCannotLoad(state, cannotLoad) {
      state.cannotLoad = cannotLoad;
    },
  },
  actions: {
    updateLocale(context, locale) {
      context.commit("setLocale", locale);
    },
    updateUploadResult(context, uploadResult) {
      context.commit("setUploadResult", uploadResult);
    },
    updateRegistrationCompleted(context, registrationCompleted) {
      context.commit("setRegistrationCompleted", registrationCompleted);
    },
    updateDocuments(context, documents) {
      context.commit("setDocuments", documents);
    },
    updateDocumentUploadSuccess(context, documentUploadSuccess) {
      context.commit("setDocumentUploadSuccess", documentUploadSuccess);
    },
    updateDocumentUploadError(context, documentUploadError) {
      context.commit("setDocumentUploadError", documentUploadError);
    },
    updateEligibility(context, eligibility) {
      context.commit("setEligibility", eligibility);
    },
    updateCertificationType(context, certificationType) {
      context.commit("setCertificationType", certificationType);
    },
    updateConsentAccepted(context, consentAccepted) {
      context.commit("setConsentAccepted", consentAccepted);
    },
    updatePersonalInfo(context, personalInfo) {
      context.commit("setPersonalInfo", personalInfo);
    },
    updateKeyExperience(context, keyExperience) {
      context.commit("setKeyExperience", keyExperience);
    },
    updateDemographics(context, demographics) {
      context.commit("setDemographics", demographics);
    },
    updateProfessionalInfo(context, professionalInfo) {
      context.commit("setProfessionalInfo", professionalInfo);
    },
    updateProfessionalReferences(context, professionalReferences) {
      context.commit("setProfessionalReferences", professionalReferences);
    },
    updateRequiredResources(context, requiredResources) {
      var documents = {};
      if (requiredResources) {
        for (var type of requiredResources) {
          documents[type.id] = [
            {
              file: null,
              uploadStatus: null,
            },
          ];
        }
      }
      context.commit("setDocuments", documents);
      context.commit("setRequiredResources", requiredResources);
    },
    updateUploadStatus(context, info) {
      context.commit("setUploadStatus", info);
    },
    async loadInitialApplication(context, id) {
      if (id === null) {
        context.commit("setNoId", true);
        return;
      }
      const schemes = await PublicApiService.getSchemes(context.getters.tenant);
      console.log("schemes", schemes["CMP"]);
      let application = await CmpBackendService.getInitialApplication(id);
      if (!application.id) {
        context.commit("setNoId", true);
        return;
      }
      if (!schemes?.["CMP"]?.certs[application.cert]) {
        context.commit("setCannotLoad", true);
      }
      context.commit("setId", application.id);
      context.commit("setCertificationType", {
        certificationPath: schemes["CMP"].certs[application.cert].name,
        certId: application.cert,
      });
      context.commit("setBookingInfo", application);
    },
  },
};

function compareCountriesAndLanguages(a, b) {
  return a.name.localeCompare(b.name);
}

export default registrationModule;
