<template>
  <v-container class="main-container d-flex flex-column justify-center">
    <v-row v-if="noId">
      <v-col cols="12" md="10" offset="0" offset-md="1" class="pt-4 textfield-margin pb-5">
        <v-alert dense color="#f0404f" outlined>
          <v-icon left color="#f0404f">$vuetify.icons.closeCircle</v-icon
          ><span>{{ $t("registration.no_id_cmp") }}</span></v-alert
        >
      </v-col>
    </v-row>
    <v-row v-if="cannotLoad">
      <v-col cols="12" md="10" offset="0" offset-md="1" class="pt-4 textfield-margin pb-5">
        <v-alert dense color="#f0404f" outlined>
          <v-icon left color="#f0404f">$vuetify.icons.closeCircle</v-icon
          ><span>{{ $t("registration.cannot_load") }}</span></v-alert
        >
      </v-col>
    </v-row>
    <div v-if="!noId && !cannotLoad" class="mx-3 mt-3 mb-10">
      <div v-if="!certificationType.certId" class="text-center">
        <v-progress-circular
          :size="50"
          :width="7"
          color="#F3EC19"
          indeterminate
          class="text-center"
        ></v-progress-circular>
      </div>
      <v-row no-gutters align="center" class="mb-2" v-if="certificationType.certId">
        <v-col cols="12" align="left">
          <div class="text-label--title-big mb-4">{{ $t("registration.steps.1") }}</div>
          <div class="text-label">
            {{ $t("registration.certification_type.info1") }}
          </div>
          <div class="text-label mb-3 mt-3">
            {{ $t("registration.certification_type.info2") }}
          </div>
          <span class="text-label"
            >{{ $t("registration.certification_type.certification_path.text") }}:
            {{ certificationType.certificationPath }}</span
          >
        </v-col>
      </v-row>
    </div>
    <!-- Navigation Buttons -->
    <div v-if="!noId && !cannotLoad && certificationType.certId">
      <v-btn tile :color="uiColor" class="next-button" v-on:click="validateForm">{{
        $t("registration.proceed")
      }}</v-btn>
      <v-btn tile :color="uiColor" class="back-button" v-on:click="$emit('back-button-clicked')">{{
        $t("registration.back")
      }}</v-btn>
    </div>
  </v-container>
</template>

<script>
import { required } from "vuelidate/lib/validators";

export default {
  name: "CertificationType",
  data() {
    return {
      tenant: this.$store.getters["registration/tenant"],
      uiColor: this.$store.getters["registration/uiColor"],
    };
  },
  validations: {
    certificationType: {
      certId: {
        required,
      },
    },
  },
  methods: {
    validateForm() {
      this.$v.$touch();
      if (!this.$v.$error) {
        this.$emit("next-button-clicked");
      }
    },
  },
  computed: {
    certificationType: {
      get() {
        return this.$store.getters["registration/certificationType"];
      },
    },
    noId() {
      return this.$store.getters["registration/noId"];
    },
    cannotLoad() {
      return this.$store.getters["registration/cannotLoad"];
    },
  },
};
</script>

<style scoped>
.main-container {
  height: 100%;
}
.textfield-margin {
  margin-bottom: -14px;
}
.v-text-field.v-text-field--enclosed .v-text-field__details {
  height: 0;
  padding: 0;
  margin: 0;
}
.black--text ::v-deep label {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.7);
}
.v-input--radio-group.v-input--radio-group--row .v-radio {
  margin-right: 8px;
}
.input-error ::v-deep .v-input__slot {
  box-shadow: 0px 3px 1px -2px rgba(240, 64, 79, 0.2), 0px 2px 2px 0px rgba(240, 64, 79, 0.2),
    0px 1px 5px 0px rgba(240, 64, 79, 0.2) !important;
}
.input-error-radio ::v-deep i {
  color: rgba(240, 64, 79, 0.8);
}
.focused ::v-deep .v-input__slot {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14),
    0px 1px 18px 0px rgba(0, 0, 0, 0.12) !important;
}
.input-error--text ::v-deep label {
  font-size: 18px;
  color: #f03434;
}
.select-cursor ::v-deep input {
  cursor: pointer !important;
}
.select-cursor ::v-deep .v-input__slot {
  cursor: pointer !important;
}
.radio-question--margin {
  margin-top: 0px !important;
  margin-right: 2rem;
  margin-bottom: 1rem;
}
.radio-block--margin {
  margin-bottom: -30px;
}
.radio-block--margin:last-of-type {
  margin-bottom: 0rem !important;
}
</style>
