export async function getSchemes(tenant) {
  for (let i = 1; i <= 5; i++) {
    try {
      const res = await fetch(process.env.VUE_APP_PUBLIC_API_URL + "/schemes/" + tenant, {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      });
      if (!res.ok) {
        console.log("request failed");
        if (i === 5) {
          return {};
        }
      } else {
        return res.json();
      }
    } catch (err) {
      console.log(err);
      if (i === 5) {
        return null;
      }
    }
  }
}
